









import { TestimonialFilter } from 'client-website-ts-library/filters';
import { API } from 'client-website-ts-library/services';
import { Testimonial } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TestimonialCard from './TestimonialCard.vue';

@Component({
  components: {
    TestimonialCard,
  },
})
export default class TestimonialCards extends Vue {
  private testimonials: Testimonial[] = [];

  @Prop()
  private readonly filter!: TestimonialFilter;

  @Prop({ default: true })
  private readonly hasReadMoreButton!: boolean;

  @Prop({ default: true })
  private readonly hasAgentPhotos!: boolean;

  @Prop({ default: true })
  private readonly hasAgentName!: boolean;

  @Prop({ default: 0 })
  private displayLimit!: number;

  private testimonialCount = 0;

  mounted() {
    this.updateTestimonials();
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      this.testimonialCount = testimonials.length;
      if (this.displayLimit === 0) {
        this.testimonials = testimonials;
      } else {
        this.testimonials = testimonials.slice(0, this.displayLimit);
      }

      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);
    });
  }

  loadMore() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      if (this.displayLimit === 0) {
        this.testimonials = testimonials;
      } else {
        this.testimonials = testimonials.slice(0, this.displayLimit += 9);
      }

      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);
    });
  }
}
